import React from "react"
import { Panel } from "../component/Panel"
import { Link } from "gatsby"
import { PageLayout } from "../component/PageLayout"

export default function Home() {
  return (
    <PageLayout>
      <Panel className="panel--no-spacing">
        <div className="banner">
          <div className="banner__inner">
            <div className="banner__header">
              We build high quality and performance based web applications
            </div>
            <div className="banner__cta">
              <Link to="/contact" className="btn">
                Contact us now
              </Link>
            </div>
          </div>
        </div>
      </Panel>

      <Panel>
        <h1 className="panel__header">Services</h1>
        <div className="service-list">
          <div className="service-list__item">
            <div className="service">
              <div className="service__image">
                <img
                  src="/images/tablet.jpg"
                  alt="Web Design and Development"
                />
              </div>
              <div className="service__heading">Web Design and Development</div>
            </div>
          </div>
          <div className="service-list__item">
            <div className="service">
              <div className="service__image">
                <img src="/images/mac.jpg" alt="Website Consultancy" />
              </div>
              <div className="service__heading">Website Consultancy</div>
            </div>
          </div>
          <div className="service-list__item">
            <div className="service">
              <div className="service__image">
                <img
                  src="/images/code-vs.jpg"
                  alt="Single page applications and API integrations"
                />
              </div>
              <div className="service__heading">
                Single page applications and API integrations
              </div>
            </div>
          </div>
          <div className="service-list__item">
            <div className="service">
              <div className="service__image">
                <img src="/images/code.jpg" alt="Responsive websites" />
              </div>
              <div className="service__heading">Responsive websites</div>
            </div>
          </div>
        </div>
      </Panel>
    </PageLayout>
  )
}
